<template>
  <div class="bg-home vh100">
    <div style="
        width: 200px;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 17%;
        transform: translate(-50%, -50%);
        text-align: center;
      ">
      <img src="@/assets/logo.png" style="width: 75%; border-radius: 50%" alt="..." />
    </div>
    <div class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span style="color: white">กรุณารอสักครู่</span>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import liff from "@line/liff";
import useSweetalert from "@/service/sweetalert2";
//import { LOGIN } from "@/store/modules/AuthModule";
import {
  // SET_PROFIRELINE,
  // SET_ACCESSTOKEN,
  // SET_PROFIRE,
  //SET_URLCALLBACK,
} from "@/store/modules/ProfireLine";
//import useLogin from "@/service/api/login";
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "Home",

  setup() {
    //const store = inject("store");
    // const router = inject("router");
    const { SconfirmNc } = useSweetalert();
    //const { CheckUser } = useLogin();

    async function InintLiff() {
      await liff
        .init({
          liffId: process.env.VUE_APP_LIFF,
        })
        .then(() => {
          if (liff.isLoggedIn()) {
            liff.getFriendship().then((data) => {
              if (data.friendFlag) {
                // liff.getProfile().then((profile) => {
                //   store.dispatch(SET_PROFIRELINE, profile);
                // });
                // const accessToken = liff.getAccessToken();
                // let at = {
                //   accesstoken: accessToken,
                // };
                // store.dispatch(SET_ACCESSTOKEN, at);
                //gettoken(accessToken);
                //window.location.replace(`line://app/${process.env.VUE_APP_REGISTERST}`);
                liff.openWindow({
                  url: `line://app/${process.env.VUE_APP_REGISTERST}`,
                  external: false,
                });
              } else {
                SconfirmNc(
                  "กรุณาเพิ่มเพื่อน ก่อนการใช้งาน",
                  "error",
                  "ตกลง"
                ).then(() => {
                  liff.openWindow({
                    url: `${process.env.VUE_APP_ADD_FRIEND}`,
                    external: false,
                  });
                });
              }
            });
          } else {


            // liff.openWindow({
            //   url: `line://app/${process.env.VUE_APP_REGISTERST}`,
            //   external: false,
            // });
             //iff.login();
             window.location.replace(`line://app/${process.env.VUE_APP_REGISTERST}`);
          }
        });
    }

    // function gettoken(accessToken) {
    //   let line = {
    //     id_line: accessToken,
    //   };
    //   store.dispatch(LOGIN, line).then(() => {
    //     CheckUser(accessToken).then((response) => {
    //       if (response.data.status === "Success") {
    //         store.dispatch(SET_PROFIRE, response.data.data);
    //         // router.push({ name: "Profile" });
    //         SconfirmNc("ขอบคุณที่ร่วมแสดงความคิดเห็น", "success", "ตกลง").then(() => {

    //           liff.ready.then(() => {
    //             liff.closeWindow();
    //           });

    //         });


    //       } else if (response.data.status === "Not Member") {
    //         router.push({ name: "Register_1" });
    //       } else {
    //         SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง");
    //       }
    //     });
    //   });
    // }

    // async function getProfile(accessToken) {
    //   return await CheckUser(accessToken);
    // }

    onMounted(() => {


      InintLiff();

    });
  },
});
</script>

<style scoped>
.bg-home {
  background-image: linear-gradient(to bottom, #80ba49, #55b08d, #2faad6);
}

.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: #58595b;
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.wrapper span {
  position: absolute;
  top: 75px;
  font-size: 21px;
  letter-spacing: 5px;
  color: white;
  left: 10%;
  font-weight: 500;
}
</style>
